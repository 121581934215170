$baseUrl: "/kdp-frontend/";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '_component/fontawesome/fontawesome';
@import '_component/fontawesome/regular';
@import '_component/fontawesome/solid';
@import '_component/layout/general';
@import '_component/top-menu/top-menu';
@import '_component/login/login';
@import '_component/form/form';
@import '_component/button/button';
@import '_component/header/header';
@import '_component/dropdown/dropdown';
@import '_component/leftside-menu/leftside-menu';
@import '_component/content/content';
@import '_component/badge/badge';
@import '_component/card/card';
@import '_component/table/table';
@import '_component/modal/modal';
@import '_component/upload/upload';
@import '_component/nav/nav';
@import '_component/employer-detail/employer';
@import '_component/member/member';
@import '_component/worker-detail/worker-detail';
@import '_component/flags/flags';
@import '_component/worksheet/print/print-sidebar';

body {
    font-family: 'prompt';
    background-color: #fafbfe;
}

html .wrapper {
    max-width: 95%;
    margin: 0 auto;
}

.wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0 auto;
}


*, ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


.apexcharts-canvas {
    margin: 0 auto;
}

figure {
    img {
        max-width: 100%;
    }
}


.list-hs-li {
    position: relative;

    li {
        position: relative;
        padding-left: 15px;


        &:last-child {
            &::before {
                content: none;
            }
        }

        &::after {
            content: '';
            width: 12.5px;
            height: 12.5px;
            border-radius: 50%;
            left: -17px;
            top: 12px;
            background-color: #F05454;
            display: block;
            position: absolute;
            z-index: 1;
        }

        &::before {
            content: '';
            width: 3px;
            height: 100%;
            top: 12px;
            left: -12px;
            background-color: #eaeaea;
            display: block;
            position: absolute;
            z-index: 00;
        }
    }
}

@media screen and (max-width: 575px) {
    h5 {
        &.text-muted {
            font-size: 1rem;
        }
    }
}


