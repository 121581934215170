@import "variables";
@import "flag-icons-base";
@import "flag-icons-list";

.kdp-content {
    .fi {
        overflow: hidden;
        border-radius: 50%;
        outline: 1px solid #fff;
        margin-left: 5px;
        margin-right: 5px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

        &.fis {
            width: 1.5em;
            height: 1.5em;
        }
    }
}
