.content-page {
    position: relative;
    margin-left: 260px;
    min-height: calc(100vh - 70px - 2px);
    padding: 0 .75rem 60px;
    transition: all .25s ease-in-out;
}

.compress-menu {
    .content-page {
        margin-left: 80px;
    }
}



@media screen and (max-width: 576px) {
    .content-page {
        margin-left: 0;
        padding: 0;
    }
    
    .compress-menu {
        .content-page {
            margin-left: 0;
        }
    }
}