#uploadfile ,
#uploadCpfile {
    position: relative;

    label {
        min-width: 60%;
    }

    input {
        &[type="file"] {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }
    }
}

.upload {
    &-file {
        position: relative;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
        padding-left: 1em;
        padding-right: 1em;
        background-color: #FAFAFA;
        border: 1px dotted #000;

        &-row {
            display: flex;
            align-items: center;
        }

        &-text {
            flex: 0 0 70%;
            width: 70%;

            &--title {
                i {
                    font-size: 16px;
                    margin-right: .75em;
                }
                span {
                    font-size: 1.2em;
                }
            }

            &--subtitle {
                span {
                    font-size: 12px;
                    color: #8C8C8C;
                }
            }
        }

        .button-main {
            flex: 0 0 30%;
            width: 30%;
        }
    }
}

.file {
    &-list {
        padding-left: 0;
        list-style: none;
        
        li {
            border-bottom: 1px solid #eaeaea;
            padding-top: .15em;
            padding-bottom: .15em;
        }
    }
}


.modal-addupload {
    #uploadfile {
        label {
            width: 100%;
        }
    }
}