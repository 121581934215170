.leftside {
    z-index: 1000;
    position: fixed;
    width: 260px;
    min-width: 260px;
    background-color: #1D395C;
    box-shadow: 0 0 35px 0 rgba($color: #828282, $alpha: .25);
    transition: all .25s ease-in-out;
    bottom: .75rem;
    top: calc(70px + 1.5rem* .5);
    border-radius: .3rem;

    .sidebar {
        &-content-wrapper {
            direction: inherit;
            box-sizing: border-box;
            position: relative;
            display: block;
            height: 100%;
            width: auto;
            max-width: 100%;
            max-height: 100%;
            scrollbar-width: none;
            overflow:  hidden scroll;
        }

        ul {
            &.side-nav {
                list-style: none;
                padding-left: 0;

                li {
                    padding-left: 1em;

                    &.side-nav-title {
                        letter-spacing: .05em;
                        pointer-events: none;
                        cursor: default;
                        white-space: nowrap;
                        text-transform: uppercase;
                        color: #8391a2;
                        padding: 15px calc(10px * 2);
                        font-size: .75em;
                    }
                   

                    &.menuitem-active {
                        a {
                            color: #fff;
                        }
                    }

                    a {
                        display: block;
                        color: #8391a2;
                        text-decoration: none;
                        padding-top: .5em;
                        padding-bottom: .5em;

                        transition: 500ms;

                        &:hover {
                            color: #fff;
                        }

                        i {
                            margin-right: 1em;
                            font-size: 1.25em;
                        }
                    }

                    .collaspe {
                        ul {
                            list-style: none;
                            margin-left: 1em;
                        }
                    }
                }

                .help-box {
                    margin-top: 4em;

                    a {
                        background-color: #F05454;
                        color: #fff;
                        display: block;
                        text-align: center;                       
                        padding-top: .5em;
                        padding-bottom: .5em;
                        text-align: center;
                        width: 95%;
                        margin: 0 auto;
                        border-radius: 5px;
                        text-decoration: none;
                        transition: 500ms;

                        &:hover {
                            background-color: #8e2b2b;
                        }
                    }
                }
            }
        }
    }
}


.compress-menu {
    .leftside {
        min-width: 70px;
        width: 70px;

        .sidebar {
            .side-nav-title {
                opacity: 0;
            }
            ul.side-nav {
                li {
                    padding-left: 0;
                    
                    a {
                        text-align: center;

                        i {
                            margin-right: 0;
                        }
                    }
                    span {
                        visibility: hidden;
                        display: none;
                    }

                    .collaspe {
                        display: none;
                    }
                }

                .help-box {
                    a {
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .leftside {
        opacity: 0;
        width: 0;
        min-width: 0;
        top: calc(5px + 1.5rem* .5);
    }

    .slide-menu-active {
        .leftside {
            opacity: 1;
            width: 260px;
            min-width: 260px;
            z-index: 1010;

            .sidebar  {
                .side-nav-title {
                    opacity: 1;
                    padding-left: 1em;
                    padding-right: 1em;
                }

                ul.side-nav {
                    li {
                        &.header-sm-logo {

                            .logo-sm {
                                margin-top: 1em;
                                max-width: 75px;

                                img {
                                    width: 100%;
                                }
                            }

                            .buttonclose {
                                color: #fff;
                                font-size: 1.2em;
                            }
                        }

                        a {
                            text-align: left;
                            padding-left: 1em;
                            padding-right: 1em;

                            span {
                                display: inline-block;
                                visibility: visible;
                                margin: 0 .25em;
                            }
                        }
                    }

                    .help-box {
                        a {
                            span {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    /*.compress-menu {
        body {
            &.slide-menu-active {
                .leftside {
                    opacity: 1;
                    width: 260px;
                    min-width: 260px;
                    z-index: 1010;

                    .sidebar  {
                        .side-nav-title {
                            opacity: 1;
                            padding-left: 1em;
                            padding-right: 1em;
                        }

                        ul.side-nav {
                            li {
                                &.header-sm-logo {

                                    .logo-sm {
                                        margin-top: 1em;
                                        max-width: 75px;
    
                                        img {
                                            width: 100%;
                                        }
                                    }

                                    .buttonclose {
                                        color: #fff;
                                        font-size: 1.2em;
                                    }
                                }

                                a {
                                    text-align: left;
                                    padding-left: 1em;
                                    padding-right: 1em;

                                    span {
                                        display: inline-block;
                                        visibility: visible;
                                        margin: 0 .25em;
                                    }
                                }
                            }

                            .help-box {
                                a {
                                    span {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }*/

    .custom-backdrop-styles {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
        z-index: 1009; /* Ensures it appears above other content */
    }
}