.kdp {
    &-nav {
        &.nav {
            border-color: #FFE3E3;

            .nav-item {
                background-color: #FFE3E3;
                padding: .25em;

                .nav-link {
                    color: #F05454;

                    &.active {
                        background-color: #F05454;
                        color: #fff;
                        border: 0;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 565px) {
    .kdp {
        &-nav {
            &.nav {
    
                .nav-item {
                    background-color: #FFE3E3;
                    padding: .25em;
    
                    .nav-link {
                        color: #F05454;
                        padding: .5em .25em;
                    }
                }
            }
        }
    }
}

.kdp {
    &-content {
        .nav-pills .nav-link.active,  
        .nav-pills .show>.nav-link {
            background-color: #F05454;
        }

        .nav {
            &-pills {
                background-color: #ffd7d7;
                border-radius: 5px;
                padding: 5px;
            }
        }

        .nav-link {
            color: #F05454;
        }
    }
}