.print {
    &-control {
        display: none;

        &-content {
            position: fixed;
            top: 0;
            right: -300px;
            background-color: #1D395C;
            z-index: 1007;
            width: 0;
            max-width: 300px;
            padding: 2em;
            height: 100vh;
            transition: all 0.25s ease-in-out;
        }
    }
}


.printmenu-active {
    height: 100%;
    overflow-y: hidden;

    .print-control {
        display: block;
        
        &-content {
            right: 0;
            width: 100%;
        }
    }

    .custom-backdrop-styles {
        position: fixed;
        background-color: rgba($color: #000000, $alpha: .5);
        width: 100vw;
        height: 100vh;
        z-index: 1006;
        top: 0;
        left: 0;
    }
}