.fib {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  
  .fi {
    @extend .fib;
    position: relative;
    display: inline-block;
    width: 1.333333 * 1em;
    line-height: 1em;
    &:before {
      content: "\00a0";
    }
    &.fis {
      width: 1em;
    }
  }
  
  @mixin flag-icon($country) {
    .fi-#{$country} {
      background-image: url(#{$flag-icons-path}#{$flag-icons-rect-path}/#{$country}.svg);
  
      @if $flag-icons-use-square {
        &.fis {
          background-image: url(#{$flag-icons-path}#{$flag-icons-square-path}/#{$country}.svg);
        }
      }
    }
  }