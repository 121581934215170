header {
    border-bottom: 1px solid #eaeaea;
    padding: 0 1em;
    min-height: 70px;
    background-color: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    z-index: 1005;
    position: sticky;
    top: 0;
}

.header {
    &-wrapper {
         max-width: 95%;
         margin: 0 auto;
    }

    &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-logo {
        &-inner {
            max-width: 60px;

            img {
                width: 100%;
            }
        }
    }

    &-navhamberger {
        button {
            padding-left: 1em;
            padding-right: 1em;
            padding-top: .5em;

            i {
                font-size: 1.5em;
            }
        }
    }

    &-search {
        form {
            height: 70px;
            max-width: 320px;
            display: flex;
            overflow: hidden;
            align-items: center;
        }

        .dropdown {
            &-items {
                display: flex;
                align-items: center;
            }
        }
    }


    &-menu {
        .desktop {
            &.row {
                flex-wrap: nowrap;
                align-items: center;
            }
        }

        ul {
            list-style: none;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-left: 0;
            margin-bottom: 0;

            li {
                padding-left: .5em;
                padding-right: .5em;

                button ,
                a.button-main {
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    padding: .25em 1em;

                    .icon {
                        display: inline-block;
                        max-width: 25px;
                        margin-right: 5px;

                        img {
                            width: 100%;
                        }
                    }
                }

                &.dropdown {
                    min-height: 70px;

                    .nav-user {
                        background-color: #fafafd;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        position: relative;
                        height: 70px;
                        gap: 10px;
                        padding-right: .75rem;
                        padding-left: .75rem;
                        text-decoration: none;
                        border-left: 1px solid #eef2f7;;
                        border-right: 1px solid #eef2f7;;

                        .icon {
                            .rounder-icon {
                                height: 35px;
                                width: 35px;
                                border-radius: 50%;
                                background-color: #050743;
                                position: relative;

                                i {
                                    color: #fff;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%,-50%);
                                    font-size: 1.3em;
                                }
                                
                                .text-name {
                                    color: #fff;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%,-50%);
                                }
                            }
                        }

                        .arrow {
                            max-width: 12px;

                            img {
                                width: 100%;
                            }
                        }

                        &-name {
                            color: #050743;
                            margin-bottom: 0;
                            font-size: 1.2em;
                        }

                        &-position {
                            margin-bottom: 0;
                            font-size: 1em;
                            color: #6c757d;
                        }
                    }
                }
            }
        }
    }

    &-profile {
        text-align: right;
        
        &-inner {
            span {
                margin-right: .25em;
                margin-left: .25em;

                &.icon {
                    display: inline-block;
                    max-width: 20px;

                    img {
                        width: 100%;
                    }
                }

                &.arrow {
                    display: inline-block;
                    max-width: 7.5px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 577px) and (max-width: 1024px) {
    .header {
        &-search {
            max-width: 250px;
        }
        &-menu {
            ul {
                li {
                    padding-left: .25em;
                    padding-right: .25em;

                    button {
                        padding: .5em .4em;

                        span {
                            font-size: 1rem;
                        }
                    }


                    &.dropdown {
                        .nav-user-name {
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 991px) {
    .header {
        &-logo {

        }
        
        &-search {
            display: none;
        }

        &-menu {
            ul {
                li {
                    .button-search {
                        i {
                            color: #6c757d;
                            font-size: 1.2em;
                        }
                    }
                    button {
                        padding: 0.25em .5em;

                        .icon {
                            margin: 0;
                        }
                    }

                    &.dropdown  {
                        .nav-user {
                            gap: 0;
                        }
                    }
                }
            }

            

            .header-menu-inners {
                button ,
                a.button-main {
                    span + span {
                        display: none;
                    }
                }

                .nav-user-name {
                    display: none;
                }

                .nav-user-position {
                    display: none;
                }

                .arrow {
                    display: none;
                }
            }
        }
    }
}


.dropdown-lg {
    width: 100%;
}