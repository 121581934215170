.badge {
    vertical-align: middle;
    display: inline-block;
    padding: 0.2em 0.4em;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    border-radius: .25em;
    line-height: 1;
    font-size: .9em;

    &-success-lighten {
        color: #0acf97;
        background-color: #e7faf5;
    }

    &-warning-lighten {
        color: #ffc35a;
        background-color: #fff9ef;
    }

    &-danger-lighten {
        color: #fa5c7c;
        background-color: #ffeff2;
    }
}

