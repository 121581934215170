button {
    background-color: transparent;
    border: 0;
}

.button {
    &-main {
        border: 0;
        width: 100%;
        padding: .5em;
        transition: 500ms;
        font-size: 16px;

        span {
            text-wrap: nowrap;
        }

        &.font-sm {
            font-size: 14px;
        }

        &.font-xs {
            font-size: 12px;
        }


        &.red {
            background-color: #F05454;
            color: #fff;

            &:hover {
                background-color: #db0202;
            }
        }


        &.violet {
            background-color: #8000FF;
            color: #fff;

            &:hover {
                background-color: #460982;
            }
        }

        &.green {
            background-color: #02A630;
            color: #fff;

            &:hover {
                background-color:#0DE74B;
            }
        }

        &.dark-navy {
            background-color: #31465E;
            color: #fff;

            &:hover {
                background-color: #023a7a;
            }
        }
    }

    &-underline {
        color: #31465E;
        text-decoration: underline;
        transition: 500ms;

        &:hover {
            color: #F05454;
        }
    }
}


.tags-badge {
    list-style: none;
    padding-left: 0;

    li {
        padding: .25em;

        button {
            padding: .25em .5em;
            background-color: #F05454;
            border: 0;
            

            span {
                font-size: .75rem;
                color: #FFE3E3;
            }
        }
    }
}



.btn {
    &-red {
        background-color: #F05454;
        color: #fff;

        &:hover {
            color: #F05454;
        }
    }

    &-dark-navy {
        background-color: #1D395C;
        color: #fff;

        &:hover {
            color: #1D395C;
        }
    }
}