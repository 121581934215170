.login {
    padding: 1em;

    &-header {
        margin-bottom:15em;

        .login-image {
            max-width: 50px;

            img {
                width: 100%;
            }
        }
    }

    &-body {
        display: flex;
        justify-content: center;
        align-items: center;

        form {
            width: 50%;

            .title-main {
                text-align: center;
                margin-bottom: 1em;
            }
        }
    }
    
    &-intro {
        background-image: url('./asset/image/login-background.jpg');
        background-position: center;
        background-size: cover;
        height: 100vh;


        &-content {
            color: #fff;

        }

        &-body {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100vh;
            padding: 2em;
        }
    }

    &-title {
        h3 {
            font-size: 4em;
            line-height: 1;
            border-bottom: 2px solid #fff;
        }

        h4 {
            font-size: 2.5em;
        }

        &-logo {
            display: inline-block;
            max-width: 150px;
           

            img {
                max-width: 100%;
                padding-bottom: .5em;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .login {
        position: relative;
        height: 100vh;

        &-header {
            margin-bottom: 0;
        }

        &-body {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            form {
                width: 100%;
                padding-left: 1.5em;
                padding-right: 1.5em;
            }
        }

        &-intro {
            display: none;
        }
    }
}