.widget-flat {
    position: relative;
    overflow: hidden;
}

.card {
    margin-bottom: 1.5rem;
    box-shadow:  0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    border: 0 solid #dee2e6;

    &.widget-flat { 
        a {
            &:not(.page-link) {
                color: #000;
            }
            text-decoration: none;

            &.btn {
                &.btn-red {
                    color: #fff;

                    &:hover {
                        color: #F05454;
                        border: 1px solid #F05454;
                        border-radius: 5px;
                    }
                }
            }
        }
    
        h5 {
            min-height: 40px;
        }
    
        h1 {
            margin-bottom: 0 !important;
    
            .slash {
    
            }
            
            .sm {
                font-size: 1.5rem;
            }
        }
    }

    .pagination {
        .active {
            & > .page-link {
                background-color: #F05454;
                border: #F05454;
                color: #fff;
            }
        }

        a {
            &.page-link {
                color:#1D395C;
            }
        }
    }
}


.main-lighten {
    background-color: #CEEBFF;
}

.main-dark {
    background-color: #1D395C;

    > * {
        color: #fff;
    }
}

.warning-lighten {
    background-color: #FFE7B7;
}

.success-lighten {
    background-color: #B7FFE1;
}

.warning {
    background-color: #ff8888;
}



@media screen and (max-width: 575px) {
    .card {
        &.widget-flat {
           // min-height: 150px;

            a {
                &:not(.page-link) {
                    color: #000;
                }

                text-decoration: none;
            }

            h5 {
                min-height: 40px;
            }

            h1 {
                margin-bottom: 0 !important;
                font-size: 2rem;

                .slash {

                }
                
                .sm {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

