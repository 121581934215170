.modal {
    &-dialog {

    }

    &-content {
        .preview-info-bu {
            padding-top: 1.5em;
            border-top: 1px solid #eaeaea;
        }

        .form-step {
            ul {
                padding-left: 0;
                list-style: none;

                li {
                    .step {
                        text-align: center;
                        
                        &-round {
                            height: 50px;
                            width: 50px;
                            position: relative;
                            background-color: #D9D9D9;
                            border-radius: 50%;
                            margin: auto;

                            span {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                                font-size: 18px;
                                color: #fff;
                            }
                        }

                        &-text {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    &-header {
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.modal-delete {
    background-color: rgba($color: #000000, $alpha: .5);
}

.step-worksheet {
    .form-step {
        ul {
            padding-left: 0;
            list-style: none;

            li {
                flex: 1;
                max-width: auto;

                .step {
                    text-align: center;
                    
                    &-round {
                        height: 50px;
                        width: 50px;
                        position: relative;
                        background-color: #D9D9D9;
                        border-radius: 50%;
                        margin: auto;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            font-size: 18px;
                            color: #fff;
                        }
                    }

                    &-text {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.form-step {
    ul {
        justify-content: center;

        li {
            position: relative;
            flex: 0 0 33.33%;
            max-width: 33.33%;

            &.active {
                .step {
                    &-round {
                        background-color: #3C4F67;
                    }

                    &::before {
                        background-color: #3C4F67;
                    }

                    &::after {
                        background-color: #3C4F67;
                    }
                }
            }

            &.current {
                .step {
                    &-round {
                        background-color: #F0545D;
                    }

                    &::before {
                        background-color: #3C4F67;
                    }
                }
            }

            &:first-child {
                .step {
                    &::before {
                        content: none;
                    }
                }
            }

            &:last-child {
                .step {
                    &::after {
                        content: none;
                    }
                    
                }
            }

            .step {
                z-index: 20;
                position: relative;

                &-round {
                    z-index: 20;
                }

                &::before {
                    content: '';
                    width: 50%;
                    display: block;
                    position: absolute;
                    top: 25px;
                    left: 0;
                    transform: translateY(-50%);
                    z-index: 19;
                    height: 5px;
                    background-color: #D9D9D9;
                }

                &::after {
                    content: '';
                    width: 50%;
                    display: block;
                    position: absolute;
                    top: 25px;
                    right: 0;
                    transform: translateY(-50%);
                    z-index: 19;
                    height: 5px;
                    background-color: #D9D9D9;
                }

            }
        }
    }
}


.modal {
    z-index: 10000;

    .select2-container {
        z-index: 10001;
    }

    table {
        width: 100%;

        thead  {
            tr  {
                th {
                    padding: .75em 0;
                }
            }
        }
    }

    &-body {
        .form-input {
            &-search {
                &.select-worker {
                    background-color: rgba($color: #F05454, $alpha: .25);
                    padding: 1em;
                    border: 1px solid #F05454;
                    margin-bottom: 2em;

                    input {
                        &.form-control {
                            background-color: #fff;
                            border: 1px solid #F05454;
                            width: 80%;
                        }
                        &[type="submit"] {
                            background-color: #3C4F67;
                            color: #fff;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            width: 20%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .step-worksheet {
        .form-step { 
            ul {
                li {
                    display: none;

                    &.current {
                        display: flex;
                        max-width: 100%;
                        align-items: center;

                        .step {
                            &::after , &::before {
                                content: none;
                            }
                        }

                        & + li {
                            display: flex;
                            opacity: .5;
                        }
                    }
                }
            }
        }
    }
}