.dropdown {
    &-menu {
        border-color: #eaeaea;
        padding: 1em;

        h5 {
            font-size: 1.25em;
            margin-bottom: 0;
        }

        .title-noti {
            h6 {
                margin-top: .5rem;
                font-size: .9em;
            }
        }
    }



    &-items {
        padding-left: 1.25em;
        padding-bottom: .25em;
        text-decoration: none;

        &:hover {
            background-color: rgb(250, 250, 253);
        }

        .icon {
            display: inline-block;
            margin-right: .75em;
            color: #050743;
        }

        p {
            margin-bottom: 0;
            

            &.dropdown {
                &-title {
                    font-size: .85em;
                    color: #050743;
                }

                &-subtitle {
                    font-size: .75em;
                    color: #F05454;
                }
            }
        }
    }

    &-item {
        border-bottom: 1px solid #eaeaea;

        &:last-child {
            border-bottom: 0;
        }
        .icon {
            margin-right: .75em;
        }
        span {
            font-size: .85em;
        }
    }
}


@media screen and (min-width: 576px) {
    .dropdown-lg {
        width: 320px;
    }
}