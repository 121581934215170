.quit {
    &-area {
        background-color: #1D395C;
        color: #fff;

        button {
            background-color: #F05454;
            border: #F05454;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            color: #fff;
        }
    }
}


.image-worker {
    input {
        &[type="file"] {
            position: absolute;
            left: 0;
            opacity: 0;
        }
    }

    &-profile {
        position: relative;
        width: 300px;
        height: 300px;  
        background-color: #eaeaea;
        overflow: hidden;
        border-top: 4px solid #eaeaea;
        border-bottom: 4px solid #eaeaea;

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 3rem;
            z-index: 10;
        }

        img {
            position: absolute;
            z-index: 12;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            &.img-thumbnail {
                background-color: transparent;
                border: none;
            }
        }

        &-info {
            background-color: #1D395C;
            color: #fff;
            padding: .5em;
            z-index: 10;
            max-width: 300px;
        }
    }

    &-upload {
        position: relative;
        border: 2px dashed #000000;
        width: 200px;
        height: 200px;  
        background-color: #F0545D;
        overflow: hidden;

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 3rem;
            z-index: 10;
        }

        img {
            position: absolute;
            z-index: 12;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            &.img-thumbnail {
                background-color: transparent;
                border: none;
            }
        }
    }

    &-info {

    }
}