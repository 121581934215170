.form {
    &-input {
        &-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: .75em;
            margin-bottom: .75em;

            &.component {
                position: relative;

                .input-component {
                    cursor: pointer;
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    margin-bottom: 12px;
                    font-size: 14px;
                    user-select: none;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;

                        &:checked {
                            ~ .checkmark {
                                background-color: #F05454; 

                                &:after {
                                    display: block;
                                }
                            }

                            ~ .checkradio {
                                &:after {
                                    display: block;
                                }
                            }
                        }
                    }

                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        background-color: #eee;

                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                            left: 8px;
                            top: 2px;
                            width: 7.5px;
                            height: 15px;
                            border: solid white;
                            border-width: 0 3px 3px 0;
                            transform: rotate(45deg);
                        }
                    }

                    &:hover {
                        input {
                            ~ .checkmark {
                                background-color: #ccc;
                            }
                        }
                    }

                    .checkradio {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 17.5px;
                        width: 17.5px;
                        border: 1px solid #F05454;
                        border-radius: 50%;

                        &:after {
                            content: "";
                            display: none;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: #F05454;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                    }
                }

                &.radio-form {
                    .radio-inner {
                        padding-right: 15px;
                    }
                    .input-component {
                        padding-left: 25px;
                    }
                }
            }

            label {
                margin-bottom: .5em;
            }

            input {
                &[type="text"] ,
                &[type="password"] ,
                &[type="number"],
                &[type="date"] {
                    border: 1px solid #eaeaea;
                    padding: .125em .75em;
                    border-radius: 2.5px;
                    font-size: 16px;

                    &:focus {
                        outline : 1px solid #F05454;
                    }
                }
            }

            &.radio-form {
                flex-direction: row;
                position: relative;

                label {
                    margin-bottom: 0;
                }
            }

            .custom-select-date {
                position: relative;

                input {
                    &[type="date"] {
                        border: 1px solid #eaeaea;
                        padding: .5em .75em;
                        border-radius: 2.5px;
                        font-size: 16px;
                        width: 100%;
    
                        &:focus {
                            outline : 1px solid #F05454;
                        }


                        ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
                        ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
                        ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
                            color: transparent;
                        }
                    }
                }
            }

            .use-bootstrap-tag  {
                &.form-control {
                    &.focus {
                        .input-wrapper {
                            input {
                                border: 0;
    
                                &:focus {
                                    border: 0;
                                }
                            }
                        }
                    }

                    .input-wrapper {
                        input {
                            border: 0;
                            padding: 0;
    
                            &:focus {
                                outline: 0;
                            }
                        }
                    }

                    .btn-secondary {
                        background-color: #FFE3E3;
                        color: #F05454;
                    }
                }
            }
        }

        &-search {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 100%;

            input {
                background-color: #f0f3f8;
                border: 0;
                position: relative;
                flex: 1 1 auto;
                width: 1%;
                min-width: 0;
                padding-left: 1em;
                border-top-left-radius: .25em;
                border-bottom-left-radius: .25em;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &::placeholder {
                    font-size: .9em;
                    color: #4e4e4e;
                }
            }

            button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: .25em;
                border-bottom-right-radius: .25em;
                position: relative;
                width: auto;
                padding-left: 1em;
                padding-right: 1em;
                box-shadow: 0 2px 6px 0 rgba($color: #F05454, $alpha: .5)
            }
        }
    }

    &-error {
        &-wrapper {
            padding: 1em;
            border:1px solid #F05454;
            background-color: rgba($color: #F05454, $alpha: .5);
            margin-bottom: 1em;

            span {
                color: #F05454;
            }
        }
    }
}





input {
    &.password-member {
        border: none;
        background: transparent;

        &:focus {
            outline: none;
        }
    }

    &.form {
        &-control {
            &-sm {
                padding: .375rem .75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                background-clip: padding-box;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            }
        }
    }
}

.select2-container {
    z-index: 9999 !important;
}

form {
    textarea {
        width: 100%;
        border: 1px solid #ced4da;
        min-height: 100px;
    }
}