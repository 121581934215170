.member {
    &-info {
        &-profile {
            margin: 1rem 0;

            .round {
                position: relative;
                width: 75px;
                height: 75px;
                background-color: #050745;
                border-radius: 50%;

                .text-name {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    color: #fff;
                    font-size: 1.75rem;
                }
            }
        }
    }
}

.owner-stage {
    .owner {
        &-only {
            display: block;
            opacity: 1;

            &.inline {
                display: inline-block;
            }
        }
    }
}

.owner {
    &-only {
        display: none;
        opacity: 0;
    }
}