.kdp-content {
    table {
        thead {
            tr {
                th {
                    background-color: #EDEAFE;
                    font-weight: 600;

                }
            }
        }
        tbody {
            tr {
                td {
                    text-transform: uppercase;

                    .arrow {
                        position: relative;

                        i {
                            position: absolute;
                            transition: 500ms;
                            top: 5px;
                            
                        }
                    }

                    .profile-thumb {
                        &-image {
                            width: 30px;
                            height: 30px;
                            position: relative;
                            overflow: hidden;
                            border-radius: 50%;
                            margin-right: 10px;
                            margin-left: 10px;
                            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                            outline: 1px solid #fff;
                            
                            img {
                                position: absolute;
                                max-width: 100%;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                            }
                        }
                    }

                    .print-button {
                        button {
                            width: 100%;
                        }
                    }
                }

                &[aria-expanded="true"] {
                    td {
                        .arrow {
                            i {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }
            .owner {
                &-name {
                    font-size: 11px;
                }
            }

            .tel {
                &-contact {
                    font-size: 12px;
                    
                    span {
                        
                    }
                }
            }
        }
    }


    .task-woar-table , .table-context-frist {
        table {
            thead {
                tr {
                    td {

                    }
                }
            }
            tbody {
                tr {
                    td {

                    }
                }
            }
        }
    }

    .employer-table , .table-number-frist {
        table {
            thead {
                tr {
                    td {
                        min-width: 100px;

                        &:first-child {
                            min-width: 25px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        min-width: 100px;

                        &:first-child {
                            min-width: 25px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1024px) {
    .kdp-content { 
        .task-work-table , .table-context-frist {
            table {
                thead {
                    tr {
                        th {
                            min-width: 150px;

                            &:first-child {
                                position: sticky;
                                left: 0;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            min-width: 150px;

                            &:first-child {
                                position: sticky;
                                left: 0;
                                background-color: rgba($color: #f7edff, $alpha: 1);
                            }
                        }
                    }
                }
            }
        }
    
        .employer-table , .table-number-frist {
            table {
                thead {
                    tr {
                        th {
                            min-width: 150px;
    
                            &:first-child {
                                min-width: 25px;
                            }


                            &:nth-child(1) {
                                position: sticky;
                                left: 0;
                            }

                            &:nth-child(2) {
                                position: sticky;
                                left: 34px;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            min-width: 150px;
    
                            &:nth-child(1) {
                                min-width: 25px;
                                position: sticky;
                                left: 0;
                            }

                            &:nth-child(2) {
                                position: sticky;
                                left: 34px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.employer-table {
    .btn {
        &.btn-danger {
            display: flex;
            align-items: center;

            i {
                font-size: 12px;
            }

            span {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}

.expland-table {
    background-color: #eaeaea;

    .profile-image {
        max-width: 150px;
        display: block;

        img {
            max-width: 100%;
        }
    }

    ul {
        list-style: none;
        
        li {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}