.employer {
    &-info {
        text-transform: uppercase;
        
        ul {
            list-style: none;
            padding-left: 0;

            &.tag-list {
                li {
                    padding-right: .5em;
                    
                    button {
                        span {
                            font-size: .75em;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 575px) {
    .tab-content {
        .list-group-item {

        }
    }
    .list-history-title {
        max-width: 70%;
        flex: 0 0 70%;

        span {
            &.label {
                width: 100%;
                display: block;
            }
        }
        a {
            span {
                margin-left: 0 !important;
            }
        }
        p {
            &.fs-6 {
                font-size: .9rem !important;
            }

            &.date {
                font-size: .75rem;
            }
        }
    }

    .list-history-type {
        text-align: right;

        p {
            font-size: .9rem !important;
        }
    }

    .employer-info {


        ul {
            &.list-hs-li {
                li {
                    &:after {
                        left: -4px;
                    }

                    &:before {
                        left: 0;
                    }
                    
                    .d-flex {
                        flex-direction: column;

                        .list-hs-date {
                            p {
                                &.text-end {
                                    &:first-child {
                                        text-align: left !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .list-hs-date {
                    display: flex;
                    justify-content: space-between;

                    .text-end {
                        display: flex;
                        flex-direction: column;

                        span {
                            margin: 0 !important;
                        }
                    }
                    p {
                        font-size: .75rem;
                    }
                }
            }

            .list-hs-dt {
                p {
                    font-size: .75rem;
                }
            }
        }
    }

}